export const editorialData = [
    {

        "TITLE": "Favs",
        "ITEMS":
            [
                "sticky+triplets",
                "ivvvo+foreveryourmouth",
                "lanarkartefax+touchabsence(intimidatingstillnessmix",
                "shawnrudiman+secrets(djmisjahremix)",
                "ledom+blossom",
                "zavoloka+sontse",
                "randomer+sheen",
                "mr.oizo+hun"
            ]
    }, {
        "TITLE":
            "dnb"
        ,
        "ITEMS":
            [
                "djss+black",
                "shapednoise+intruder",
                "currentvalue+consequences",
                "squarepusher+venusno.17",
                "bizzyb+strength",
                "squarepusher+sarcacid,pt.2",
                "noisia&mayhemfeat.krs-one+exodus(vocalversion)",
                "paradox+theunspokendivide",
                "squarepusher+dedicatedloop"
            ]
    }, {
        "TITLE":
            "acid house"
        ,
        "ITEMS":
            [
                "thefuturesoundoflondon+accelerator",
                "d-shake+setthecontrolsfortheheartofthegroove",
                "strafe+setitoff",
                "shawnrudiman+secrets(djmisjahremix)",
                "blupeter+widescreen&digital"
            ]
    },{
        "TITLE":
            "breakbeat"
        ,
        "ITEMS":
            [
                "2badmice+waremouse",
            ]
    },
];

