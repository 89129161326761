const genreMap = {
    '#ACH': 'Acid House',
    '#ABS': 'Abstract',
    '#ACI': 'Acid',
    '#AMB': 'Ambient',
    // '#AVA': 'Avantgarde',
    '#BAS': 'Bass',
    '#BRE': 'Breaks',
    '#BRN': 'Breakbeat',
    // '#CLA': 'Classical',
    // '#DEE': 'Deep House',
    '#DNB': 'Drum n Bass',
    // '#EBM': 'EBM',
    // '#DIS': 'Disco',
    // '#DRO': 'Drone',
    // '#DAR': 'Dark Ambient',
    // '#DOW': 'Downtempo',
    // '#DUB': 'Dub',
    '#DUS': 'Dubstep',
    '#ELE': 'Electro',
    // '#ELC': 'Electronic',
    '#EXP': 'Experimental',
    // '#FUN': 'Funk',
    // '#GAB': 'Gabber',
    '#GAR': 'Garage',
    '#GLI': 'Glitch',
    '#GRI': 'Grime',
    // '#HAH': 'Hard House',
    '#HAR': 'Hardcore',
    '#HIP': 'Hip Hop',
    '#HOU': 'House',
    '#IDM': 'IDM',
    '#IND': 'Industrial',
    '#JUK': 'Juke',
    '#JUN': 'Jungle',
    // '#MIN': 'Minimal',
    '#MOD': 'Modern Classical',
    '#MUS': 'Musique Concrète',
    '#NEW': 'New Wave',
    '#NOI': 'Noise',
    // '#POP': 'Pop',
    // '#PRH': 'Progressive House',
    // '#PRT': 'Progressive Trance',
    // '#REG': 'Reggaeton',
    // '#SPE': 'Speedcore',
    '#SYN': 'Synth-pop',
    // '#TEH': 'Tech House',
    '#TEC': 'Techno',
    // '#TRP': 'Trap',
    '#TRA': 'Trance',
    // '#TRI': 'Tribal',
    // '#TRN': 'Neo Trance',
};

export default genreMap;